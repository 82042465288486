<template>
  <el-dialog title="新建项目"
             :close-on-click-modal="false"
             :visible.sync="systemDialog"
             width="60%"
             append-to-body
  >
    <el-steps
        :space="200"
        :active="activeIndexSystem - 0"
        finish-status="success"
        align-center
    >
      <el-step title="项目信息"></el-step>
      <el-step title="项目系统"></el-step>
      <el-step title="子系统"></el-step>
      <el-step title="设备"></el-step>
      <el-step title="子设备"></el-step>
      <el-step title="子设备字段"></el-step>
      <el-step title="确认"></el-step>
    </el-steps>
    <el-form
        :model="addForm"
        :rules="rules"
        ref="addForm"
        :label-position="addFormLabelPosition"
        label-width="100px"
    >
      <el-tabs :tab-position="'left'" v-model="activeIndexSystem">
        <el-tab-pane label="项目信息" name="0">
          <el-row style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item label="新项目名称" prop="coalName">
                <el-input
                    style="width: 66%"
                    v-model="addForm.coalName"
                    placeholder="请输入系统名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="地址信息" prop="address">
                <el-input
                    style="width: 66%"
                    v-model="addForm.address"
                    placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="产量信息" prop="output">
                <el-input
                    style="width: 66%"
                    v-model="addForm.output"
                    placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item label="煤质信息" prop="quality">
                <el-input
                    style="width: 66%"
                    v-model="addForm.quality"
                    placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开采方式" prop="miningWay">
                <el-input
                    style="width: 66%"
                    v-model="addForm.miningWay"
                    placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="灾害类型" prop="disaster">
                <el-input
                    style="width: 66%"
                    v-model="addForm.disaster"
                    placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item label="服务年限" prop="serviceNum">
                <el-input
                    style="width: 66%"
                    v-model="addForm.serviceNum"
                    placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="项目系统" name="1">
          <el-form-item label="添加系统">
            <div
                v-for="systemName in addForm.systemName"
                :key="systemName.id"
            >
              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <el-input
                      style="width: 66%; margin-bottom: 5px"
                      v-model="systemName.value"
                      placeholder="请输入系统名称"
                      @keyup.native="
                      updateSystemOptions(systemName.id, systemName.value)
                    "
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <el-input
                      style="width: 66%; margin-bottom: 5px"
                      v-model="systemName.remark"
                      placeholder="添加系统描述"
                  >
                  </el-input>
                </el-col>
              </el-row>
              <el-row style="margin-bottom: 10px">
                <el-col :span="6">
                  <el-button
                      class="addSystemButton"
                      type="primary"
                      size="small"
                      @click="addSystemName"
                      round
                  >+
                  </el-button>
                </el-col>

              </el-row>
            </div>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="子系统" name="2">
          <el-form-item label="添加子系统">
            <el-row style="margin-bottom: 10px">
              <el-col :span="8">
                <el-select
                    @change="systemChange(addForm.addSystemSelect)"
                    style="width: 66%; margin-bottom: 5px"
                    v-model="addForm.addSystemSelect"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in addForm.addSystemOptions"
                      :key="item.id"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-alert
                    style="width: 66%; height: 100%; margin-bottom: 5px"
                    v-if="subSystemAlter"
                    :closable="false"
                    title="请选择系统"
                    type="error"
                    effect="dark"
                >
                </el-alert>
              </el-col>
            </el-row>
            <div
                v-for="subsystemName in addForm.subsystemName"
                :key="subsystemName.id"
            >
              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <el-input
                      @focus="subSystemFocus"
                      :disabled="subSystemDisabled"
                      style="width: 66%; margin-bottom: 5px"
                      v-model="subsystemName.value"
                      @keyup.native="
                      updateSubSystemOptions(
                        subsystemName.id,
                        subsystemName.value
                      )
                    "
                      placeholder="请输入子系统名称"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <el-input
                      @focus="subSystemFocus"
                      :disabled="subSystemDisabled"
                      style="width: 66%; margin-bottom: 5px"
                      v-model="subsystemName.remark"
                      placeholder="添加子系统描述"
                  ></el-input>
                </el-col>
              </el-row>
              <el-row style="margin-bottom: 10px">
                <el-col :span="6">
                  <el-button
                      class="addSystemButton"
                      type="primary"
                      size="small"
                      @click="addSubsystemName"
                      round
                  >+
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="设备" name="3"
        >
          <el-form-item label="添加设备">
            <el-row style="margin-bottom: 10px">
              <el-col :span="8">
                <el-select
                    @change="
                    subSystemChange(
                      addForm.addSystemSelect,
                      addForm.addSubSystemSelect
                    )
                  "
                    style="width: 66%; margin-bottom: 5px"
                    v-model="addForm.addSubSystemSelect"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in addForm.addSubSystemOptions"
                      :key="item.id"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-alert
                    style="width: 66%; height: 100%; margin-bottom: 5px"
                    v-if="deviceAlter"
                    :closable="false"
                    title="请选择子系统"
                    type="error"
                    effect="dark"
                >
                </el-alert>
              </el-col>
            </el-row>
            <div v-for="device in addForm.device" :key="device.id">
              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <el-input
                      @focus="deviceFocus"
                      :disabled="deviceDisabled"
                      style="width: 66%; margin-bottom: 5px"
                      v-model="device.value"
                      @keyup.native="updateDeviceOptions(device.id, device.value)"
                      placeholder="请输入设备名称"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <el-input
                      @focus="deviceFocus"
                      :disabled="deviceDisabled"
                      style="width: 66%; margin-bottom: 5px"
                      v-model="device.remark"
                      placeholder="添加设备描述"
                  ></el-input>
                </el-col>
              </el-row>
              <el-row style="margin-bottom: 10px">
                <el-col :span="6">
                  <el-button
                      class="addSystemButton"
                      type="primary"
                      size="small"
                      @click="addDevice"
                      round
                  >+
                  </el-button
                  >
                </el-col>
              </el-row>
            </div>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="子设备" name="4">
          <el-form-item label="添加子设备">
            <el-row style="margin-bottom: 10px">
              <el-col :span="8">
                <el-select
                    @change="
                    deviceChange(
                      addForm.addSystemSelect,
                      addForm.addSubSystemSelect,
                      addForm.addDeviceSelect
                    )
                  "
                    style="width: 66%; margin-bottom: 5px"
                    v-model="addForm.addDeviceSelect"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in addForm.addDeviceOptions"
                      :key="item.id"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-alert
                    style="width: 66%; height: 100%; margin-bottom: 5px"
                    v-if="subDeviceAlter"
                    :closable="false"
                    title="请选择子系统"
                    type="error"
                    effect="dark"
                >
                </el-alert>
              </el-col>
            </el-row>
            <div v-for="subDevice in addForm.subDevice" :key="subDevice.id">
              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <el-input
                      @focus="subDeviceFocus"
                      :disabled="subDeviceDisabled"
                      style="width: 66%; margin-bottom: 5px"
                      v-model="subDevice.value"
                      @keyup.native="
                      updateSubDeviceOptions(subDevice.id, subDevice.value)
                    "
                      placeholder="请输入附属设备名称"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <el-input
                      @focus="subDeviceFocus"
                      :disabled="subDeviceDisabled"
                      style="width: 66%; margin-bottom: 5px"
                      v-model="subDevice.remark"
                      placeholder="添加附属设备描述"
                  ></el-input>
                </el-col>
              </el-row>
              <el-row style="margin-bottom: 10px">
                <el-col :span="6">
                  <el-button
                      class="addSystemButton"
                      type="primary"
                      size="small"
                      @click="addSubDevice"
                      round
                  >+
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="附属设备字段" name="5">
          <el-form-item label="添加附属设备字段">
            <el-row style="margin-bottom: 10px">
              <el-col :span="8">
                <el-select
                    @change="
                    subDeviceChange(
                      addForm.addSystemSelect,
                      addForm.addSubSystemSelect,
                      addForm.addDeviceSelect,
                      addForm.addSubDeviceSelect
                    )
                  "
                    style="width: 66%; margin-bottom: 5px"
                    v-model="addForm.addSubDeviceSelect"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in addForm.addSubDeviceOptions"
                      :key="item.id"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-alert
                    style="width: 66%; height: 100%; margin-bottom: 5px"
                    v-if="subDeviceFieldAlter"
                    :closable="false"
                    title="请选择附属设备"
                    type="error"
                    effect="dark"
                >
                </el-alert>
              </el-col>
            </el-row>


            <div
                v-for="subDeviceField in addForm.subDeviceField"
                :key="subDeviceField.id"
            >
              <el-row style="margin-bottom: 10px">
                <el-col :span="8">
                  <el-input
                      @focus="subDeviceFieldFocus"
                      :disabled="subDeviceFieldDisabled"
                      @keyup.native="
                      updateSubDeviceFieldOptions(
                        subDeviceField.id,
                        subDeviceField.value
                      )
                    "
                      style="width: 66%; margin-bottom: 5px"
                      v-model="subDeviceField.value"
                      placeholder="请输入字段名称"
                  >
                  </el-input>
                </el-col>
                <el-col :span="8">
                  <el-input
                      @focus="subDeviceFieldFocus"
                      :disabled="subDeviceFieldDisabled"
                      style="width: 66%; margin-bottom: 5px"
                      v-model="subDeviceField.remark"
                      placeholder="添加字段描述"
                  >
                  </el-input>
                </el-col>
              </el-row>
              <el-row style="margin-bottom: 10px">
                <el-col :span="6">
                  <el-button
                      class="addSystemButton"
                      type="primary"
                      size="small"
                      @click="addSubDeviceField"
                      round
                  >+
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="确认" name=6>
          <el-row style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item
                  label="新系统简介"
                  prop="remark"
                  style="margin-bottom: 10px"
              >
                <el-input
                    style="width: 100%"
                    type="textarea"
                    :rows="3"
                    v-model="addForm.remark"
                    placeholder="请输入简介信息"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
              label="当前操作员"
              prop="currOperatorName"
              style="margin-bottom: 10px"
          >
            <el-input
                style="width: 20%"
                class="small-input"
                v-model="addForm.currOperatorName"
                placeholder="管理员user"
                disabled
            ></el-input>
          </el-form-item>

          <el-form-item style="margin-bottom: 10px">
            <el-button type="primary" @click="submitForm('addForm')"
            >立即创建
            </el-button
            >
            <el-button @click="resetForm('addForm')">重置</el-button>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>

    <span slot="footer" class="dialog-footer">
          <el-button @click="giveUpAdd()">取 消</el-button>
          <el-button type="primary" @click="systemDialog = false"
          >保 存</el-button
          >
        </span>
  </el-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "addCoal",
  data() {
    return {
      systemDialog: false,
      activeIndexSystem: 0,
      addFormLabelPosition: 'top',
      addForm: {
        data: [{
          id: 0,
          value: '',
          remark: '',
          subsystemName: [{
            id: 0,
            value: '',
            remark: '',
            device: [{
              id: 0,
              value: '',
              remark: '',
              subDevice: [{
                id: 0,
                value: '',
                remark: '',
                subDeviceField: [{
                  id: 0,
                  value: '',
                  remark: '',
                }]
              }]
            }]
          }]
        }],
        addSubDeviceSelect: '',
        addSubDeviceOptions: [
          {
            id: 0,
            value: '',
          }
        ],
        addDeviceSelect: '',
        addDeviceOptions: [
          {
            id: 0,
            value: '',
          }
        ],
        addSubSystemSelect: '',
        addSubSystemOptions: [
          {
            id: 0,
            value: '',
          }
        ],
        addSystemSelect: '',
        addSystemOptions: [
          {
            id: 0,
            value: '',
          }
        ],
        coalName: '',
        remark: '',
        currOperator: '',
        subDeviceField: [{
          id: 0,
          value: '',
          remark: '',
        }
        ],
        device: [{
          id: 0,
          value: '',
          remark: '',
        }
        ],
        subDevice: [{
          id: 0,
          value: '',
          remark: '',
        }
        ],
        subsystemName: [{
          id: 0,
          value: '',
          remark: '',
        }
        ],
        serviceNum: '',
        disaster: '',
        miningWay: '',
        quality: '',
        systemName: [{
          id: 0,
          value: '',
          remark: '',
        }
        ],
        address: '',
        output: '',
        date: '',
      },
      rules: {
        coalName: [
          {required: true, message: '请输入项目名称', trigger: 'blur'},
        ],
        address: [
          {required: true, message: '请输入地址信息', trigger: 'blur'},
        ],
        output: [
          {required: true, message: '请输入产量信息', trigger: 'blur'},
        ],
        quality: [
          {required: true, message: '请输入煤质信息', trigger: 'blur'},
        ],
        miningWay: [
          {required: true, message: '请输入开采方式', trigger: 'blur'},
        ],
        disaster: [
          {required: true, message: '请输入灾害类型', trigger: 'blur'},
        ],
        serviceNum: [
          {required: true, message: '请输入服务年限', trigger: 'blur'}
        ],
        remark: [
          {required: true, message: '请输入新系统简介', trigger: 'blur'}
        ],
      },
      subSystemAlter: false,
      deviceAlter: false,
      subDeviceAlter: false,
      subDeviceFieldAlter: false,
      subSystemDisabled: false,
      deviceDisabled: false,
      subDeviceDisabled: false,
      subDeviceFieldDisabled: false,
    }
  },
  methods: {
    open() {
      this.systemDialog = true
    },
    close() {
      this.systemDialog = false
    },
    addSystemName() {
      let data = {
        id: 0,
        value: '',
        remark: '',
        subsystemName: [{
          id: 0,
          value: '',
          remark: '',
          device: [{
            id: 0,
            value: '',
            remark: '',
            subDevice: [{
              id: 0,
              value: '',
              remark: '',
              subDeviceField: [{
                id: 0,
                value: '',
                remark: '',
              }]
            }]
          }]
        }]
      }
      let id = this.addForm.systemName.length;
      let data1 = {
        id: 0,
        value: '',
        remark: '',
      }
      let data2 = {
        id: id,
        value: data.value
      }
      data.id = id
      data1.id = id
      this.addForm.systemName.push(data1)
      this.addForm.addSystemOptions.push(data2)
      this.addForm.data.push(data)
    },
    addSubsystemName() {
      let data = {
        id: 0,
        value: '',
        remark: '',
        device: [{
          id: 0,
          value: '',
          remark: '',
          subDevice: [{
            id: 0,
            value: '',
            remark: '',
            subDeviceField: [{
              id: 0,
              value: '',
              remark: '',
            }]
          }]
        }]
      }
      let id = this.addForm.subsystemName.length
      let data1 = {
        id: 0,
        value: '',
        remark: '',
      }
      let data2 = {
        id: id,
        value: data.value
      }
      data.id = id
      data1.id = id
      this.addForm.subsystemName.push(data1)
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          this.addForm.data[i].subsystemName.push(data)
          break;
        }
      }
      this.addForm.addSubSystemOptions.push(data2)
    },
    addDevice() {
      let data = {
        id: 0,
        value: '',
        remark: '',
        subDevice: [{
          id: 0,
          value: '',
          remark: '',
          subDeviceField: [{
            id: 0,
            value: '',
            remark: '',
          }]
        }]
      }
      let id = this.addForm.device.length
      let data1 = {
        id: 0,
        value: '',
        remark: '',
      }
      let data2 = {
        id: id,
        value: data.value
      }
      data.id = id
      data1.id = id
      this.addForm.device.push(data1)
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (this.addForm.addSubSystemSelect === this.addForm.data[i].subsystemName[j].value) {
              this.addForm.data[i].subsystemName[j].device.push(data)
              break;
            }
          }
          break;
        }
      }
      this.addForm.addDeviceOptions.push(data2)
    },
    addSubDevice() {
      let data = {
        id: 0,
        value: '',
        remark: '',
        subDeviceField: [{
          id: 0,
          value: '',
          remark: '',
        }]
      }
      let id = this.addForm.subDevice.length
      let data1 = {
        id: 0,
        value: '',
        remark: '',
      }
      let data2 = {
        id: id,
        value: data.value
      }
      data.id = id
      data1.id = id
      this.addForm.subDevice.push(data1)
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (this.addForm.addSubSystemSelect === this.addForm.data[i].subsystemName[j].value) {
              for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
                if (this.addForm.addDeviceSelect === this.addForm.data[i].subsystemName[j].device[k].value) {
                  this.addForm.data[i].subsystemName[j].device[k].subDevice.push(data)
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.addForm.addSubDeviceOptions.push(data2)
    },
    addSubDeviceField() {
      let data = {
        id: 0,
        value: '',
        remark: '',
      }
      let id = this.addForm.subDeviceField.length
      data.id = id
      this.addForm.subDeviceField.push(data)
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (this.addForm.addSubSystemSelect === this.addForm.data[i].subsystemName[j].value) {
              for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
                if (this.addForm.addDeviceSelect === this.addForm.data[i].subsystemName[j].device[k].value) {
                  for (let l = 0; l < this.addForm.data[i].subsystemName[j].device[k].subDevice.length; l++) {
                    if (this.addForm.addSubDeviceSelect === this.addForm.data[i].subsystemName[j].device[k].subDevice[l].value) {
                      this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField.push(data)
                      break;
                    }
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    },
    updateSystemOptions(id, value) {
      for (let i = 0; i < this.addForm.addSystemOptions.length; i++) {
        if (id === this.addForm.addSystemOptions[i].id) {
          this.addForm.addSystemOptions[i].value = value
          break;
        }
      }
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (id === this.addForm.data[i].id) {
          this.addForm.data[i].value = value
          break;
        }
      }
    },
    systemChange(system) {
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          if (this.addForm.data[i].subsystemName.length === 1) {
            this.addForm.subsystemName = [{
              id: this.addForm.data[i].subsystemName[0].id,
              value: this.addForm.data[i].subsystemName[0].value,
              remark: this.addForm.data[i].subsystemName[0].remark
            }]
          } else {
            this.addForm.subsystemName = []
            for (let m = 0; m < this.addForm.data[i].subsystemName.length; m++) {
              let data = {
                id: this.addForm.data[i].subsystemName[m].id,
                value: this.addForm.data[i].subsystemName[m].value,
                remark: this.addForm.data[i].subsystemName[m].remark,
              }
              this.addForm.subsystemName.push(data)
            }
          }
          break;
        }
      }
      this.addForm.addSubSystemOptions = []
      this.addForm.addSubSystemSelect = ''
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            let data = {
              id: this.addForm.data[i].subsystemName[j].id,
              value: this.addForm.data[i].subsystemName[j].value
            }
            this.addForm.addSubSystemOptions.push(data)
          }
          break;
        }
      }
      this.addForm.addDeviceSelect = ''
      this.addForm.addDeviceOptions = [{
        id: 0,
        value: ''
      }
      ]
      this.addForm.addSubDeviceSelect = ''
      this.addForm.addSubDeviceOptions = [{
        id: 0,
        value: ''
      }
      ]
      this.addForm.device = [{
        id: 0,
        value: '',
        remark: ''
      }]
      this.addForm.subDevice = [{
        id: 0,
        value: '',
        remark: ''
      }]
      this.addForm.subDeviceField = [{
        id: 0,
        value: '',
        remark: ''
      }]
      this.subSystemAlter = false;
      this.subSystemDisabled = false;
    },
    subSystemFocus() {
      if (this.addForm.addSystemSelect === '') {
        this.subSystemAlter = true;
        this.subSystemDisabled = true;
      }
    },
    updateSubSystemOptions(id, value) {
      for (let i = 0; i < this.addForm.addSubSystemOptions.length; i++) {
        if (id === this.addForm.addSubSystemOptions[i].id) {
          this.addForm.addSubSystemOptions[i].value = value
          break;
        }
      }
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (id === this.addForm.data[i].subsystemName[j].id) {
              this.addForm.data[i].subsystemName[j].value = value
              break;
            }
          }
          break;
        }
      }
    },
    subSystemChange(system, subSystem) {
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (subSystem === this.addForm.data[i].subsystemName[j].value) {
              if (this.addForm.data[i].subsystemName[j].device.length === 1) {
                this.addForm.device = [{
                  id: this.addForm.data[i].subsystemName[j].device[0].id,
                  value: this.addForm.data[i].subsystemName[j].device[0].value,
                  remark: this.addForm.data[i].subsystemName[j].device[0].remark
                }]
              } else {
                this.addForm.device = []
                for (let m = 0; m < this.addForm.data[i].subsystemName[j].device.length; m++) {
                  let data = {
                    id: this.addForm.data[i].subsystemName[j].device[m].id,
                    value: this.addForm.data[i].subsystemName[j].device[m].value,
                    remark: this.addForm.data[i].subsystemName[j].device[m].remark,
                  }
                  this.addForm.device.push(data)
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.addForm.addDeviceOptions = []
      this.addForm.addDeviceSelect = ''
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (subSystem === this.addForm.data[i].subsystemName[j].value) {
              for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
                let data = {
                  id: this.addForm.data[i].subsystemName[j].device[k].id,
                  value: this.addForm.data[i].subsystemName[j].device[k].value
                }
                this.addForm.addDeviceOptions.push(data)
              }
              break;
            }
          }
          break;
        }
      }
      this.addForm.subDevice = [{
        id: 0,
        value: '',
        remark: ''
      }]
      this.addForm.subDeviceField = [{
        id: 0,
        value: '',
        remark: ''
      }]
      this.deviceAlter = false;
      this.deviceDisabled = false;
    },
    deviceFocus() {
      if (this.addForm.addSubSystemSelect === '') {
        this.deviceAlter = true;
        this.deviceDisabled = true;
      }
    },
    updateDeviceOptions(id, value) {
      for (let i = 0; i < this.addForm.addDeviceOptions.length; i++) {
        if (id === this.addForm.addDeviceOptions[i].id) {
          this.addForm.addDeviceOptions[i].value = value
          break;
        }
      }
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (this.addForm.addSubSystemSelect === this.addForm.data[i].subsystemName[j].value) {
              for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
                if (id === this.addForm.data[i].subsystemName[j].device[k].id) {
                  this.addForm.data[i].subsystemName[j].device[k].value = value
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    },
    deviceChange(system, subSystem, device) {
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (subSystem === this.addForm.data[i].subsystemName[j].value) {
              for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
                if (device === this.addForm.data[i].subsystemName[j].device[k].value) {
                  if (this.addForm.data[i].subsystemName[j].device[k].subDevice.length === 1) {
                    this.addForm.subDevice = [{
                      id: this.addForm.data[i].subsystemName[j].device[k].subDevice[0].id,
                      value: this.addForm.data[i].subsystemName[j].device[k].subDevice[0].value,
                      remark: this.addForm.data[i].subsystemName[j].device[k].subDevice[0].remark
                    }]
                  } else {
                    this.addForm.subDevice = []
                    for (let m = 0; m < this.addForm.data[i].subsystemName[j].device[k].subDevice.length; m++) {
                      let data = {
                        id: this.addForm.data[i].subsystemName[j].device[k].subDevice[m].id,
                        value: this.addForm.data[i].subsystemName[j].device[k].subDevice[m].value,
                        remark: this.addForm.data[i].subsystemName[j].device[k].subDevice[m].remark,
                      }
                      this.addForm.subDevice.push(data)
                    }
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.addForm.addSubDeviceOptions = []
      this.addForm.addSubDeviceSelect = ''
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (subSystem === this.addForm.data[i].subsystemName[j].value) {
              for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
                if (device === this.addForm.data[i].subsystemName[j].device[k].value) {
                  for (let l = 0; l < this.addForm.data[i].subsystemName[j].device[k].subDevice.length; l++) {
                    let data = {
                      id: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].id,
                      value: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].value
                    }
                    this.addForm.addSubDeviceOptions.push(data)
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.addForm.subDeviceField = [{
        id: 0,
        value: '',
        remark: ''
      }]
      this.subDeviceAlter = false;
      this.subDeviceDisabled = false;
    },
    subDeviceFocus() {
      if (this.addForm.addDeviceSelect === '') {
        this.subDeviceAlter = true;
        this.subDeviceDisabled = true;
      }
    },
    updateSubDeviceOptions(id, value) {
      for (let i = 0; i < this.addForm.addSubDeviceOptions.length; i++) {
        if (id === this.addForm.addSubDeviceOptions[i].id) {
          this.addForm.addSubDeviceOptions[i].value = value
          break;
        }
      }
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (this.addForm.addSubSystemSelect === this.addForm.data[i].subsystemName[j].value) {
              for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
                if (this.addForm.addDeviceSelect === this.addForm.data[i].subsystemName[j].device[k].value) {
                  for (let l = 0; l < this.addForm.data[i].subsystemName[j].device[k].subDevice.length; l++) {
                    if (id === this.addForm.data[i].subsystemName[j].device[k].subDevice[l].id) {
                      this.addForm.data[i].subsystemName[j].device[k].subDevice[l].value = value
                      break;
                    }
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    },
    subDeviceChange(system, subSystem, device, subDevice) {
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (system === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (subSystem === this.addForm.data[i].subsystemName[j].value) {
              for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
                if (device === this.addForm.data[i].subsystemName[j].device[k].value) {
                  for (let l = 0; l < this.addForm.data[i].subsystemName[j].device[k].subDevice.length; l++) {
                    if (subDevice === this.addForm.data[i].subsystemName[j].device[k].subDevice[l].value) {
                      if (this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField.length === 1) {
                        this.addForm.subDeviceField = [{
                          id: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[0].id,
                          value: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[0].value,
                          remark: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[0].remark
                        }]
                      } else {
                        this.addForm.subDeviceField = []
                        for (let m = 0; m < this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField.length; m++) {
                          let data = {
                            id: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[m].id,
                            value: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[m].value,
                            remark: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[m].remark,
                          }
                          this.addForm.subDeviceField.push(data)
                        }
                      }
                      break;
                    }
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      this.subDeviceFieldAlter = false;
      this.subDeviceFieldDisabled = false;
    },
    subDeviceFieldFocus() {
      if (this.addForm.addSubDeviceSelect === '') {
        this.subDeviceFieldAlter = true;
        this.subDeviceFieldDisabled = true;
      }
    },
    updateSubDeviceFieldOptions(id, value) {
      for (let i = 0; i < this.addForm.data.length; i++) {
        if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
          for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
            if (this.addForm.addSubSystemSelect === this.addForm.data[i].subsystemName[j].value) {
              for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
                if (this.addForm.addDeviceSelect === this.addForm.data[i].subsystemName[j].device[k].value) {
                  for (let l = 0; l < this.addForm.data[i].subsystemName[j].device[k].subDevice.length; l++) {
                    if (this.addForm.addSubDeviceSelect === this.addForm.data[i].subsystemName[j].device[k].subDevice[l].value) {
                      for (let m = 0; m < this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField.length; l++) {
                        if (id === this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[m].id) {
                          this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[m].value = value
                          break;
                        }
                      }
                      break;
                    }
                  }
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.postRequest("/addDataAccessTask/insertCoal", this.addForm).then(response => {
            this.$alert(response.data, '创建项目', {
              confirmButtonText: '确定',
              // callback: action => {
              //   this.$message({
              //     type: 'info',
              //     message: `action: ${ action }`
              //   });
              // }
            });
          }).then(error => {
            // console.log(error)
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.addForm.data = [{
        id: 0,
        value: '',
        remark: '',
        subsystemName: [{
          id: 0,
          value: '',
          remark: '',
          device: [{
            id: 0,
            value: '',
            remark: '',
            subDevice: [{
              id: 0,
              value: '',
              remark: '',
              subDeviceField: [{
                id: 0,
                value: '',
                remark: '',
              }]
            }]
          }]
        }]
      }]
      this.addForm.systemName = [{
        id: 0,
        value: '',
        remark: '',
      }]
      this.addForm.subsystemName = [{
        id: 0,
        value: '',
        remark: '',
      }]
      this.addForm.device = [{
        id: 0,
        value: '',
        remark: '',
      }]
      this.addForm.subDevice = [{
        id: 0,
        value: '',
        remark: '',
      }]
      this.addForm.subDeviceField = [{
        id: 0,
        value: '',
        remark: '',
      }]
      this.addForm.addSystemOptions = [{
        id: 0,
        value: ''
      }]
      this.addForm.addSubSystemOptions = [{
        id: 0,
        value: ''
      }]
      this.addForm.addDeviceOptions = [{
        id: 0,
        value: ''
      }]
      this.addForm.addSubDeviceOptions = [{
        id: 0,
        value: ''
      }]
      this.addForm.addSystemOptions[0].id = this.addForm.systemName[0].id
      this.addForm.addSystemOptions[0].value = this.addForm.systemName[0].value
      this.addForm.addSystemSelect = ''
      this.subSystemAlter = false;
      this.subSystemDisabled = false;
      this.addForm.addSubSystemOptions[0].id = this.addForm.subsystemName[0].id
      this.addForm.addSubSystemOptions[0].value = this.addForm.subsystemName[0].value
      this.addForm.addSubSystemSelect = ''
      this.deviceAlter = false;
      this.deviceDisabled = false;
      this.addForm.addDeviceOptions[0].id = this.addForm.device[0].id
      this.addForm.addDeviceOptions[0].value = this.addForm.device[0].value
      this.addForm.addDeviceSelect = ''
      this.subDeviceAlter = false;
      this.subDeviceDisabled = false;
      this.addForm.addSubDeviceOptions[0].id = this.addForm.subDevice[0].id
      this.addForm.addSubDeviceOptions[0].value = this.addForm.subDevice[0].value
      this.addForm.addSubDeviceSelect = ''
      this.subDeviceFieldAlter = false;
      this.subDeviceFieldDisabled = false;
    },
    giveUpAdd() {
      this.resetForm("addForm");
      this.systemDialog = false;
    },
  },
}
</script>

<style scoped>

</style>
